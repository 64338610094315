<template>
  <div>
    <!--nav-->
    <div class="db pc">
      <div class="commen">
        <!-- <div class="dbp">
          湖南拓建教育科技有限公司致力于系统集成、网络安全、安防监控、数据中心、企业IT运维等服务。
        </div> -->
      </div>
    </div>
    <div class="top pc">
      <div class="commen">
        <div class="logo fl">
          <a href="#"> <img src="img//logo3.png" /></a>
        </div>
        <div class="logoc fr">
          <!-- <img src="img//x1.png" /> -->
          <!-- <p><span>0532-8872 0333</span></p> -->
          <div class="clearfix"></div>
        </div>
        <div class="nav fr">
          <div :class="item.class" v-for="item,index in navData" :key="index">
            <p><a :href="item.url">{{item.name}}</a></p>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  <!--手机导航-->
    <div class="sjdhgd sjdhgd_n">
      <div
        class="sjdh mob"
        style="
          padding-top: 4%;
          padding-bottom: 4%;
          box-shadow: rgb(51, 153, 255) 0px 0px 0px 0px;
        "
      >
        <div class="sjdha">
          <div class="sjdha_t1">
            <a href="#/"> <img src="img/y30@2.png" /></a>
          </div>
          <div class="sjdha_t2">
            <img src="img/k52.png" />
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="huakuai">
          <div class="sjdhb fr">
            <div class="guanbi">
              <img src="img/k53.png" />
              <div class="clearfix"></div>
            </div>
            <div class="sjdhb_a sjdhb_ah1">
              <h6><a href="#/">拓建首页</a></h6>
            </div>
            <div class="sjdhb_a">
              <h6><a href="business">主营业务</a></h6>
              <div class="sjdhb_aa">
                <p class="sjdhb_p">
                  <a href="#/system/" title="系统集成"
                    >系统集成</a
                  >
                </p>
                <p class="sjdhb_p">
                  <a href="#/maintenance/" title="IT运维"
                    >IT运维</a
                  >
                </p>
                <p class="sjdhb_p">
                  <a href="#/network/" title="网络安全"
                    >网络安全</a
                  >
                </p>
                <p class="sjdhb_p">
                  <a href="#/data_center/" title="数据中心"
                    >数据中心</a
                  >
                </p>
              </div>
            </div>
            <div class="sjdhb_a">
              <h6>
                <a href="#/system_integration/">成功案例</a>
              </h6>
              <div class="sjdhb_aa">
                <p class="sjdhb_p">
                  <a href="#/system_integration/"
                    >系统集成</a
                  >
                </p>
                <p class="sjdhb_p">
                  <a href="#/system_operation/">IT运维</a>
                </p>
                <p class="sjdhb_p">
                  <a href="#/cyber_security/">网络安全</a>
                </p>
                <p class="sjdhb_p">
                  <a href="#/data_center_case/">数据中心</a>
                </p>
                <p class="sjdhb_p">
                  <a href="#/yuancheng/partner.html"
                    >合作客户</a
                  >
                </p>
              </div>
            </div>
            <div class="sjdhb_a">
              <h6><a href="#/news/">新闻中心</a></h6>
            </div>
            <div class="sjdhb_a">
              <h6>
                <a href="#/yuancheng/about.html"
                  >联系我们</a
                >
              </h6>
              <div class="sjdhb_aa">
                <p class="sjdhb_p">
                  <a href="#/yuancheng/about.html"
                    >公司介绍</a
                  >
                </p>
                <p class="sjdhb_p">
                  <a href="#/yuancheng/team.html"
                    >团队介绍</a
                  >
                </p>
                <p class="sjdhb_p">
                  <a href="#/yuancheng/enterprise.html"
                    >企业理念</a
                  >
                </p>
                <p class="sjdhb_p">
                  <a href="#/yuancheng/corporate.html"
                    >企业文化</a
                  >
                </p>
              </div>
            </div>
            <div class="sjdhb_a">
              <h6>
                <a href="#/yuancheng/contact.html"
                  >联系我们</a
                >
              </h6>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <router-view />



    <!--footer-->
    <div class="yj">
      <div class="commen">
        <div class="yja fl">
          <h6 class="yj_h6">主营业务</h6>
          <p>
            <span ><a href="#" title="系统集成">系统集成</a></span>
            <a href="#">基础网络</a>
            <a href="#">安防监控</a>
            <!-- <a href="#">协助通信</a> -->
            <a href="#">多媒体影音</a>
            <!-- <a href="#">视频会议</a> -->
          </p>
          <p>
            <span><a href="#" title="IT运维">IT运维</a></span>
            <a href="#">驻场服务</a>
            <a href="#">应急服务</a>
            <a href="#">远程服务</a>
            <!-- <a href="#">IDC巡检</a> -->
          </p>
          <p>
            <span><a href="#" title="网络安全">网络安全</a></span>
            <!-- <a href="#">边界安全</a> -->
            <a href="#">内网安全</a>
            <a href="#">数据安全</a>
            <a href="#">云计算安全</a>
            <a href="#">安全服务</a>
          </p>
          <p>
            <span><a href="#" title="数据中心">数据中心</a></span>
            <a href="#">数据中心建设</a>
            <a href="#">数据中心管理</a>
            <!-- <a href="#">关键电源管理</a> -->
            <a href="#">设备更新及改造</a>
            <!-- <a href="#">异地迁移安置</a> -->
          </p>
        </div>
        <div class="yjb fr pc">
          <h6 class="yj_h6">快速导航</h6>
          <p>
            <span><a href="#">联系我们</a></span>
            <a href="#">公司介绍</a>
            <a href="#">团队介绍</a>
            <a href="#">企业理念</a>
            <a href="#">企业文化</a>
          </p>
          <!-- <p>
            <span><a href="#">成功案例</a></span>
            <a href="#">系统集成</a>
            <a href="#">IT运维</a>
            <a href="#">网络安全</a>
            <a href="#">数据中心</a>
          </p> -->

          <div class="yjba">
            <img src="img/logo5.png" />
            <div class="clearfix"></div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="yjc commen">
        <p class="fl">版权所有©湖南拓建教育科技有限公司</p>
        <p class="fl">备案号：<a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow" >湘ICP备2021011524号</a></p>
        <p class="fr">
          <!-- <a href="#" target="_blank"></a>
          <a href="#" target="_blank"></a> -->
        </p>
        <div class="clearfix"></div>
      </div>
    </div>
    <!-- <div class="yl pc">
      <p>
        友情链接：<a href="http://www.net532.net/" target="_blank"
          >青岛网站建设</a
        >　|　
        <a href="http://www.i-reagent.com/" target="_blank">乙酰蒲公英萜醇</a
        >　|　
        <a href="http://www.tianshuohuanbao.com/" target="_blank"
          >FM138ACR模块</a
        >　|　
        <a href="http://www.nxzxjzzl.com/" target="_blank">银川吊篮租赁</a>　|　
        <a href="http://www.bjtczk.net/" target="_blank">服装防盗钉</a>　|　
        <a href="http://www.szshixu.cn/" target="_blank">FFU</a>　|　
        <a href="http://www.zxdpump.cn/" target="_blank">Alcatel真空泵维修</a
        >　|　
        <a href="https://www.qxjm.net/" target="_blank">重庆监控安装</a>　|　
        <a href="http://www.forlaw.cn/" target="_blank">铝管</a>　|　
        <a href="http://www.yanuochina.com/" target="_blank">灼热丝试验仪</a
        >　|　
      </p>
    </div> -->
    <div class="yb_conct pc" style="right: -127px">
      <div class="yb_bar">
        <ul>
          <li class="yb_top">返回顶部</li>
          <!-- <li class="yb_phone">0532-8872 0333</li>
          <li class="yb_QQ">
            <a
              target="_blank"
              href="http://wpa.qq.com/msgrd?v=3&amp;uin=381940158&amp;site=www.qdycwl.com&amp;menu=yes"
              >QQ在线</a
            >
          </li>
          <li class="yb_ercode" style="height: 53px">
            微信二维码 <br />
            <img class="hd_qr" src="img/ewm.png" />
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
$(function () {
  $(".sjdha_t2").click(function () {
    $(".huakuai").css("right", "0%");
  });
  $(".guanbi").click(function () {
    $(".huakuai").css("right", "-100%");
  });
});
</script>
            <script>
$(window).scroll(function () {
  var m = $(window).scrollTop();
  if (m >= 50) {
    $(".sjdh").css({
      "padding-top": "3%",
      "padding-bottom": "3%",
      "box-shadow": "0 -2px 18px -2px #3399ff",
    });
  } else {
    $(".sjdh").css({
      "padding-top": "4%",
      "padding-bottom": "4%",
      "box-shadow": "0 -0 0 0 #3399ff",
    });
  }
});
</script>
    <script>
function CheckSubmit2() {
  var body = document.getElementById("body").value;
  if (body == "") {
    alert("请输入您需要咨询的内容！");
    return false;
  } else if (body == "我们将会为您提供合适的解决方案_") {
    alert("请输入您需要咨询的内容！");
    return false;
  } else {
    return true;
  }
}
</script>
<script>
import "@/assets/js/push.js";
import "@/assets/js/easing.min.js";
import "@/assets/js/scrollReveal.js";
import Swiper from 'swiper/dist/idangerous.swiper.js'
export default {
  data() {
    return {
      navData: [
        { name: '拓建首页', class:'nava fl', url:'/index'},
        { name: '主营业务', class:'nava fl',url:'/businesslist'},
        { name: '新闻中心', class:'nava fl',url:'/articlelist'},
        { name: '联系我们', class:'nava fl',url:'/about'}
      ]
    };
  },
  created() {},
  mounted() {
    console.log(this.$route.name);
    let routeName = this.$route.name
    this.navData.forEach(i=> {
      // console.log(i.url);
      if(i.url == '/'+routeName) {
        i.class = 'nava fl navaa'
      } else {
        i.class = 'nava fl'
      }
    })
    // $(document).ready(function() {
    //   $(".nava").each(function(i,el){
    //     $(el).removeClass('navaa')
    //   })
    //   let url = window.location.href
    //   if(url.indexOf('business')) {
    //     $(".nava").each(function(i,el){
    //       $(el).addClass('navaa')
    //     })
    //   }

    // })
    //下拉菜单
  //   $(document).ready(function () {
  //     $(".navb").slideUp();
  //     $(".nava").mouseenter(function () {
  //       $(this)
  //         .children(".navb")
  //         .stop(true, true)
  //         .slideDown(200)
  //         .css("opacity", "1");
  //     });
  //     $(".nava").mouseleave(function () {
  //       $(this)
  //         .children(".navb")
  //         .stop(true, true)
  //         .slideUp(300)
  //         .css("opacity", "0");
  //     });
  //   });
  },
};
</script>

<style>
@import "~@/assets/style/yc.css";
@import "~@/assets/style/swiper.min.css";
@import "~@/assets/style/animate.min.css";
</style>